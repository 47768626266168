@function grid($number) {
  $grid: 0.25rem;
  @return $number * $grid;
}

/* ----- Accessor functions for maps ----- */
@function get($map, $key) {
  $keys: str-explode($key, '.');

  @if not deep-map-check($map, $keys...) {
    @error 'The argument $map: `#{$map}` doesn\'t has some of the $keys: `#{$keys}`!';
  }

  @return deep-map-get($map, $keys...);
}

@function str-explode($string, $delimiter: '') {
  @if type-of($string) != 'string' {
    @error 'The argument $string: `#{$string}` is of incorrect type: `#{type-of($string)}`. Type of `String` is required!';
  } @else if type-of($delimiter) != 'string' {
    @error 'The argument $string: `#{$string}` is of incorrect type: `#{type-of($string)}`. Type of `String` is required!';
  }

  $result: ();
  $running: true;

  @if str-length($delimiter) == 0 {
    @return $string;
  }

  @while $running {
    $index: str-index($string, $delimiter);

    @if $index != null {
      $item: str-slice($string, 1, ($index - 1));
      $result: append($result, $item);
      $string: str-slice($string, ($index + str-length($delimiter)));
    } @else {
      $running: false;
    }
  }

  @return append($result, $string);
}

@function deep-map-check($map, $keys...) {
  @if type-of($map) != 'map' {
    @error 'The argument $map: `#{$map}` is of incorrect type: `#{type-of($map)}`. Type of `Map` is required!';
  }

  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }

    $map: map-get($map, $key);
  }

  @return true;
}

@function deep-map-get($map, $keys...) {
  @if type-of($map) != 'map' {
    @error 'The argument $map: `#{$map}` is of incorrect type: `#{type-of($map)}`. Type of `Map` is required!';
  }

  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}
