@use '@angular/cdk';
@use 'vars' as *;
@use 'placeholders' as *;
@import '@angular/cdk/overlay-prebuilt.css';

@include cdk.a11y-visually-hidden();

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;

  html,
  body {
    height: 100vh;
    box-sizing: border-box;
    font-size: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    font-family: 'ff-tisa-sans-web-pro', sans-serif;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul,
  a,
  button {
    margin: 0;
    padding: 0;
    font-weight: normal;
    color: $color-dark-primary;
  }

  a {
    @extend %nav-link;
    text-decoration: none;
    cursor: pointer;
  }

  button {
    border: 0;
    background: none;
    cursor: pointer;
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-block-start: 0;
    padding-block-end: 0;
    min-inline-size: 0px;
  }

  legend {
    padding: 0;
    margin: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    border: none;
  }
}

app-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

app-about {
  padding: $content-component-padding;
}

::-webkit-scrollbar {
  width: $vertical-scrollbar-thickness;
  height: $horizontal-scrollbar-thickness;
  border-radius: 70px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $color-scrollbar;
  border-radius: 70px;
}
